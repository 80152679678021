import { gsap } from 'gsap/all';

class Follower {
    constructor(root, handleClick = () => {}) {
        this.$root = root;
        this.$handleClick = handleClick;
        this.$visible = false;

        const button = document.createElement('div');
        button.dataset.direction = null;
        button.classList.add('slider__follow');
        button.innerHTML = this.getButtonTemplate();
        this.$root.prepend(button);
        this.$button = this.$root.querySelector('.slider__follow');

        window.addEventListener('mousemove', (e) => this.moveFollow(e));
        window.addEventListener('resize', (e) => this.moveFollow(e));

        this.$button.addEventListener('click', () =>
            this.$handleClick(this.$button.dataset.direction)
        );

        this.$root.addEventListener('mouseenter', () => {
            this.updateVisibility(true);
        });
        this.$root.addEventListener('mouseleave', () => {
            this.updateVisibility(false);
        });
    }

    getButtonTemplate() {
        return `
            <svg class="icon-left" xmlns="http://www.w3.org/2000/svg" width="20.445" height="21.176"
                 viewBox="0 0 20.445 21.176">
                <g id="Gruppe_19926" data-name="Gruppe 19926" transform="translate(0.707 0.354)">
                    <path id="Pfad_15590" data-name="Pfad 15590" d="M10333.029,4621h-19.35"
                          transform="translate(-10313.291 -4610.766)" fill="none" stroke="#000"
                          stroke-width="1"/>
                    <path id="Pfad_15591" data-name="Pfad 15591"
                          d="M10333.234,4611.774,10323,4622.009l10.234,10.234"
                          transform="translate(-10323 -4611.774)" fill="none" stroke="#000"
                          stroke-width="1"/>
                </g>
            </svg>

            <svg class="icon-right" xmlns="http://www.w3.org/2000/svg" width="20.445" height="21.176"
                 viewBox="0 0 20.445 21.176">
                <g id="Gruppe_19926" data-name="Gruppe 19926" transform="translate(0 0.354)">
                    <path id="Pfad_15590" data-name="Pfad 15590" d="M10313.68,4621h19.35"
                          transform="translate(-10313.68 -4610.766)" fill="none" stroke="#000"
                          stroke-width="1"/>
                    <path id="Pfad_15591" data-name="Pfad 15591"
                          d="M10323,4611.774l10.234,10.234L10323,4632.243"
                          transform="translate(-10313.496 -4611.774)" fill="none" stroke="#000"
                          stroke-width="1"/>
                </g>
            </svg>`;
    }

    updateVisibility(val) {
        this.$visible = val;
    }

    moveFollow(e) {
        const positionPercentage = (100 * e.x) / window.innerWidth;

        if (!this.$button) return;

        if (this.$visible && positionPercentage <= 20) {
            this.$button.style.display = 'flex';
            this.$button.classList.add('animate-scale');
            this.$button.dataset.direction = 'left';
        } else if (this.$visible && positionPercentage >= 80) {
            this.$button.style.display = 'flex';
            this.$button.classList.add('animate-scale');
            this.$button.dataset.direction = 'right';
        } else {
            this.$button.style.display = 'none';
            this.$button.classList.remove('animate-scale');
            this.$button.dataset.direction = null;
        }

        const rect = this.$root.getBoundingClientRect();
        const left =
            Math.floor(e.clientX - rect.left) - this.$button.clientWidth / 2;
        const top =
            Math.floor(e.clientY - rect.top) - this.$button.clientHeight / 2;

        gsap.to(this.$button, 0.0, { css: { left, top } });
    }
}

export default Follower;
